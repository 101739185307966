.root {
  margin: auto;
  height: 100vh !important;
  width: 100vw !important;
  overflow: scroll !important;
  z-index: 100 !important;
}
.root::after {
  content: '';
  position: absolute;
  height: 120%;
  width: 100vw !important;
  top: 0;
  background: url(../assets/Pattern.png);
  opacity: 0.1;
  z-index: -1;
}

.flip-book {
  display: flex;
  width: 90% !important;
  transition: all ease-in-out 0.3s;
}

.page {
  box-shadow: 0 0 10px black;
  border: solid 1px hsl(35, 20, 70);
  overflow: hidden;
  width: fit-content !important;
}
.first_page {
  box-shadow: 0 0 0 !important;
  border: solid 1px hsl(35, 20, 70);
  display: none !important;
  width: fit-content !important;
}
.page_img {
  height: 100%;
  background-size: contain !important;
  background-position: center center;
  background-repeat: no-repeat;
}

.menu_book {
  height: 70vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.page_no {
  position: absolute;
  color: #181640;
  bottom: 0;
  padding: 1vmin;
  display: none;
}
.menu_headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  width: 80%;
  margin: auto;
}

.menu_header_btn {
  margin: 2vmin !important;
  min-width: fit-content !important;
  color: #181640 !important;
  font-size: large !important;
  font-weight: bolder !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: scale(1);
  transition: all ease-in-out 0.2s !important;
}
.menu_header_btn:hover {
  transform: scale(1.2);
}
.menu_title {
  text-align: center;
  margin-bottom: 3vmin;
  padding: 1vmin;
  background-color: #181640;
}
.menu_title_img {
  width: 30% !important;
  color: white;
  mix-blend-mode: multiply;
}
.btn_img {
  width: 40px !important;
  margin: 1vmin;
}
.btn_img_left {
  /* transform: rotate(255deg); */
}
.btn_img_right {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.menu_title_text {
  color: white;
}
.nav_title {
  width: 10% !important;
}
@media screen and (max-width: 1000px) {
  .nav_title {
    width: 30% !important;
  }
}
.footer {
  width: 80%;
  margin: auto;
}
.footer_side {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer_container {
  width: 65% !important;
  margin: auto !important;
}
@media screen and (max-width: 1000px) {
  .footer_container {
    width: 80% !important;
  }
}
.footer_text {
  color: #181640;
  font-weight: 400 !important;
}
.footer_socials {
  display: flex;
  width: 80% !important;
  justify-content: space-evenly;
}
.footer_social_btn {
  width: fit-content !important;
  aspect-ratio: 1;
  border-radius: 100% !important;
}
.footer_social_btn_icon {
  color: #181640;
}

.nav_root {
  padding: 1vmin;
  display: flex;
  z-index: 100;
}
.nav_logo {
  width: 150px;
  margin: auto;
}
